//@ts-nocheck
"use client";

import React, { useEffect, useLayoutEffect } from "react";
import { debounce, useMediaMatch } from 'daskapital-ds';

import ScrollTrigger from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import onAppearAnimations from "@/lib/helpers/animations";
import { useGlobalContext } from "@/lib/globalContext";

const NavigationEvents: any = ({
  translatedURI,
  acf,
}: {
  translatedURI: Record<string, string>;
  acf?: Record<string, string>;
}) => {
  const { lenis, translatedUri, setTranslatedUri } = useGlobalContext();

  useEffect(() => {
    if (JSON.stringify(translatedUri) !== JSON.stringify(translatedURI)) {
      if (setTranslatedUri && translatedURI) setTranslatedUri(translatedURI);
    }
  }, [translatedUri, translatedURI, setTranslatedUri]);

  //force resize to make sure every animation is in right place;
  const resetScrollTriggerPos = debounce(() => {
    const triggers = ScrollTrigger.getAll();
    triggers.forEach((trigger, i) => {
      trigger.refresh();
    })

    ScrollTrigger.refresh()
  }, 50)

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      lenis?.scrollTo(0, { immediate: true, force: true });

      onAppearAnimations();

      const animationSelector = gsap.utils.selector("body");
      if (acf) {
        document.documentElement.classList.remove(`style-light`, `style-dark`);
        document.documentElement.classList.remove(`header-absolute`);

        if (acf.page_style) {
          document.documentElement.classList.add(`style-${acf.page_style}`);
        }

        if (acf.header_absolute) {
          document.documentElement.classList.add(`header-absolute`);
        }
      }

      const header = document.getElementById("masthead");
      gsap.timeline({
        scrollTrigger: {
          trigger: "body",
          start: () => "top top",
          end: () => `bottom bottom`,
          invalidateOnRefresh: true,
          onToggle: (self) => {
            self.progress > 0 ? header?.classList.add('scrolled') : header?.classList.remove('scrolled');
          }
        },
      });


      //custom animations

      setTimeout(() => {
        resetScrollTriggerPos();
      }, 250);
    });

    return () => ctx.revert(); // <- cleanup!
  }, [acf, lenis]);


  useLayoutEffect(() => {
    //anchors by slug
    if (location) {
      const hash = location.pathname.slice(0, -1).split("/").pop();
      if (hash && document.getElementById(hash)) {
        (document.getElementById(hash) as HTMLDivElement).scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, []);

  return null;
};
export default NavigationEvents;
